<div class="container">
  <amplify-authenticator [loginMechanisms]="['username']" [hideSignUp]="true">
    <ng-template amplifySlot="sign-in-header">
      <p class="amplify-heading">
        Sign in to your account
      </p>
    </ng-template>
    <ng-template amplifySlot="authenticated" mat-dialog-close>
    </ng-template>
  </amplify-authenticator>
</div>